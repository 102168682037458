import React, { useState } from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const AdminPage = () => {
  const [user, setUser] = useState(null);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());

 
  return (
    <Layout>
      <div className="container mx-auto px-5 p-5">
        
        <Tabs className="bg-gameOnLightGreen">
          <TabList>
            <Tab>Professionals Report</Tab>
            <Tab>Users Report</Tab>
          </TabList>

          <TabPanel>
            <div className="flex flex-col p-10">

              <label htmlFor="date-range" className="mb-2 text-base">Select a date range:</label>
              <DatePicker
                id="date-range"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  // Array destructuring is used to access the start and end date range
                  const [start, end] = update;
                  setStartDate(start);
                  setEndDate(end);
                }}
                className="form-input block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:via-gameOnLighterGreen focus:border-gameOnDarkGreenActive sm:text-sm"
              />

              <div className="my-5 w-full inline-block mx-auto text-center">
                <button
                  className="inline-block w-full px-20 py-5 leading-none text-white font-outfit font-medium text-base rounded-3xl hover:border-transparent lg:mt-0 bg-gameOnGreen hover:bg-gameOnLightGreenActive"
                >
                  Generate Report
                </button>
              </div>

              <div className="dataDetails">
                <p className="font-md">Showing <strong>16</strong> professionals sorted selected date.</p>
                  <hr className="mt-5" />
              </div>
              
              <div className="dataOutput">

                <div className="">
                  <h3 className="text-2xl font-semibold">Aaron Ferrell</h3>
                  <ul>
                    <li>Email: aaronlagana@outlook.com</li>
                    <li>Sessions: <strong>3</strong></li>
                    <li>Chat: <strong>3490</strong></li>
                  </ul>
                </div>

                <hr className="my-5" />

                <div className="">
                  <h3 className="text-2xl font-semibold">J.P. Quinn Coaching</h3>
                  <ul>
                    <li>Email: winwithquinn22@gmail.com</li>
                    <li>Sessions: <strong>6</strong></li>
                    <li>Chat: <strong>3490</strong></li>
                  </ul>
                </div>

              </div>

            </div>
          </TabPanel>
          <TabPanel>
            <div className="flex flex-col p-10">
              
              <label htmlFor="date-range" className="mb-2 text-base">Select a date range:</label>
              <DatePicker
                id="date-range"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  const [start, end] = update;
                  setStartDate(start);
                  setEndDate(end);
                }}
                className="form-input block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:via-gameOnLighterGreen focus:border-gameOnDarkGreenActive sm:text-sm"
              />

              <div className="my-5 w-full inline-block mx-auto text-center">
                <button
                  className="inline-block w-full px-20 py-5 leading-none text-white font-outfit font-medium text-base rounded-3xl hover:border-transparent lg:mt-0 bg-gameOnGreen hover:bg-gameOnLightGreenActive"
                >
                  Generate Report
                </button>
              </div>

              <div className="dataDetails">
                <p className="font-md">Showing <strong>51</strong> filtered users sorted by selected date.</p>
                  <hr className="mt-5" />
              </div>
              
              <div className="dataOutput">

                <div className="dataUserDetails border-b-[1px] py-10 border-x-gameOnDarkGreen">
                  <div className="grid grid-cols-4 gap-4">
                    <div className="flex flex-wrap items-center justify-between">
                      <div className="rounded-full bg-gameOnDarkGreen text-white text-center w-20 h-20 p-6">
                        <div className="text-2xl">E</div>
                      </div>
                    </div>
                    <div className="col-span-3">
                      <ul>
                        <li><h3 className="font-semibold">Iliana hernandez (verified)</h3></li>
                        <li>Email: iliananana@aol.com</li>
                        <li>Account created: <strong>05:14 pm 5 Nov</strong></li>
                        <li>Signup completed: <strong>05:19 pm 5 Nov</strong></li>
                        <li>Verified on: <strong>05:18 pm 5 Nov</strong></li>
                        <li>Payment: <strong>Not paid</strong></li>
                        <li>Booked: <strong>No Booking</strong></li>
                        <li>How did you hear about us?: <strong>Social Media</strong></li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="dataUserDetails my-5">
                  <div className="grid grid-cols-4 gap-4">
                    <div className="flex flex-wrap items-center justify-between">
                      <div className="rounded-full bg-gameOnDarkGreen text-white text-center w-20 h-20 p-6">
                        <div className="text-2xl">M</div>
                      </div>
                    </div>
                    <div className="col-span-3">
                      <ul>
                        <li><h3 className="font-semibold">Marisa Klopfenstein  (verified)</h3></li>
                        <li>Email: marisabklop@gmail.com</li>
                        <li>Account created: <strong>10:46 pm 2 Nov</strong></li>
                        <li>Signup completed: <strong>10:48 pm 2 Nov</strong></li>
                        <li>Verified on: <strong>10:47 pm 2 Nov</strong></li>
                        <li>Payment: <strong>Not paid</strong></li>
                        <li>Booked: <strong>10:49 pm 2 Nov</strong></li>
                        <li>How did you hear about us?: <strong>Search Engine</strong></li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </TabPanel>
        </Tabs>
        
      </div>
    </Layout>
  );
}

export const Head = () => <Seo title="Admin Page" />

export default AdminPage;